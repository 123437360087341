.mpimgcon {
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.mpfabric {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  gap: 10px;

  .row1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .row2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
}

.horizontal-list {
  display: flex;
  justify-content: start;  
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
}

.styled-hr {
  border: 0;
  border-top: 1px solid #3498db;
  color: #3498db;
  background-color: #3498db;
  height: 1px;
  width: 100%;
}

.fabmaster-con {
  display: flex;
  justify-content: space-between;
}
.closeRound {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  cursor: pointer;
  color: #fff;
  background-color: #3c3d37;
}

.mainprod-card {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;

  .row {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .imgcon {
      height: 70px;
      overflow: hidden;
      border-radius: 20%;
      padding: 5px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        aspect-ratio: 3/2;
      }
    }   
  }
  .del {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(
      255,
      255,
      255,
      0.8
    ); /* Semi-transparent background */
    border-radius: 50%;
    z-index: 99;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Hover effects */
    color: #C5705D;
  }

  .del:hover {
    color:#fff;
    background-color: rgba( 255,0,0,0.8); /* Change background color on hover */
    transform: scale(1.1); /* Slightly enlarge on hover */
  }
}
