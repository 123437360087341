.footer-heading{
    font-size: xx-large;
    font-weight: 600;
    padding-bottom: 10px;
    cursor: pointer;

    > :nth-child(1) {
      color: #F9B62F !important;
    }
  
    > :nth-child(2) {
      color: #FDFEFE;
    }
 }

 .footer-descp{
    text-align: left;
    color: #FDFEFE;
    line-height: 1.5rem;
 }


.footer-main{
    display: flex;
    flex-direction: column;
}

 .footer-subheading{
    font-size: x-large;
    font-weight: 600;
    padding-bottom: 10px;
    color: #FDFEFE;    
    cursor: pointer;
 }

 .footer-info{
    color: #FDFEFE;
    text-align: left;
    position: relative;
    display: inline-block;
    padding-bottom: 10px; 
    cursor: pointer;
    text-transform: uppercase;

    &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 2px; /* Adjust the thickness of the underline */
        bottom: 0;
        left: 0;
        background-color: #F9B62F; /* Color of the underline */
        transition: width 0.3s ease-in-out;
    }

    &:hover:before {
        width: 100%; /* Expand the underline on hover */
    }
}

.footer-copywrite{
    display: flex;
    justify-content: center;
    align-items: center;
    color:#FDFEFE;
}
 