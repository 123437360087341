.fileInput {
    display: none;
    margin-bottom: 10px;
  }
  
  .fileInputLabel {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    background-color: #1976d2;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .fileInputLabel:hover {
    background-color: #1565c0;
  }
  
  .fileInputIcon {
    margin-right: 8px;
  }
  .imageListItem {
    position: relative!important;
  }
  .imageWrapper {
    position: relative;
  }
  
  .deleteIcon {
    position: absolute!important;
    top: 2px;
    right: 2px;
    color: white!important;
    background-color: rgba(0, 0, 0, 0.7)!important;
    border-radius: 50%;
    padding: 2px!important;
    z-index: 999;
  }

  .error {
    color: red;
    font-size: 12px;
    margin-top: 4px;
  }