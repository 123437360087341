/* Ensure the ImageList container does not overflow */
.image-list {
    overflow-x: hidden; /* Prevent horizontal scrolling */
    width: 100%; /* Ensure it takes the full width of its container */
    gap: 100px;
    margin-bottom: 10px;
}
  
/* Card styling */
.pc-card {
    position: relative;
    border-radius: 8px; /* Rounded corners for card effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Card shadow effect */
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition effects */
    cursor: pointer;

    /* Ensure images fit well within the card */
    img {
      border-radius: 8px 8px 0 0; /* Rounded top corners for the image */
      object-fit: cover; /* Cover the entire card */
      width: 100%;
      height: 100%; /* Ensure image height fills the card */
    }

    /* Hover effect for cards */
    &:hover {
      transform: scale(1.02); /* Slight zoom effect on hover */
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
    }
}
  
/* Styling for the ImageListItemBar */
.pc-card-bar {
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    color: #000; /* Black text color */
    border-radius: 0 0 8px 8px; /* Rounded bottom corners for the bar */
    padding: 5px;
    font-weight: 600;
    display: flex;
    justify-content: space-between; /* Space between name and icon */
    align-items: center;
    transition: background 0.3s ease;
}

/* Product name styling */
.product-name {
    font-size: 14px;
    color: #000; /* Default color is black */
    transition: color 0.3s ease; /* Smooth transition for hover */
}

/* Favorite icon styling */
.favorite-icon {
    color: #000; /* Default icon color is black */
    transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for hover */
}
  
/* Hover animations */
.pc-card-bar:hover .product-name {
    color: #000; /* Change name color to white on hover */
}

.pc-card-bar:hover .favorite-icon {
    color: #f9b62f; /* Change icon color on hover */
    transform: scale(1.2); /* Slight zoom effect on icon hover */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .image-list {
      gap: 16px; /* Adjust gap for mobile view if needed */
    }

    .pc-card {
      margin: 0;
    }
}
