.app-bar {
  background-color: #fff !important;
  color: #000;
  overflow: hidden; // Ensures no horizontal scroll
}
.appbar-main {
  display: flex;
  flex-direction: column;
  // width: 100%;
  padding: 0 !important;
  margin: 0 !important;
}

.appbar-contact {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  padding: 10px 0;

  a {
    color: #fff5e4;
    padding: 5px;
  }

  @media (max-width: 700px) {
    justify-content: flex-end;
  }
}

.hr {
  width: 1px; /* Thickness of the line */
  height: 10px; /* Length of the line */
  background-color: #fff; /* Color of the line */
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.appbar-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;

  .appbar-imgcon {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    .appbar-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      aspect-ratio: 3/2;
    }
  }
}

.appbar-heading {
  font-size: x-large;
  font-weight: 600;
  color: #000;
  cursor: pointer;
  margin-left: -15px !important;
}

.appbar-iconcon {
  padding-right: 40px;
  cursor: pointer;
}


