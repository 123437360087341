/* Ensure the ImageList container does not overflow */
.image-list {
    overflow-x: hidden; /* Prevent horizontal scrolling */
    width: 100%; /* Ensure it takes the full width of its container */
    gap: 100px; 
    margin-bottom: 10px;
  }
  
  /* Card styling */
  .pc-card {
    position: relative;
    border-radius: 8px; /* Rounded corners for card effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Card shadow effect */
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition effects */
    cursor: pointer;
    /* Ensure images fit well within the card */
    img {
      border-radius: 8px 8px 0 0; /* Rounded top corners for the image */
      object-fit: cover; /* Cover the entire card */
      width: 100%;
      height: 100%; /* Ensure image height fills the card */
    }
    
    /* Hover effect for cards */
    &:hover {
      transform: scale(1.02); /* Slight zoom effect on hover */
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
    }
  }
  
  /* Styling for the ImageListItemBar */
  .pc-card-bar {
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for the bar */
    color: #000; /* Text color in the bar */
    border-radius: 0 0 8px 8px; /* Rounded bottom corners for the bar */
    padding:5px;
    font-weight: 600;
  }
  
  /* Responsive design adjustments */
  @media (max-width: 768px) {
    .image-list {
      gap: 16px; /* Adjust gap for mobile view if needed */
    }
  
    .pc-card {
      margin: 0;
    }
  }
  