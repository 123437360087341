.cbar-main{
    display:flex;
    padding:80px 40px 0 40px!important;
    flex-direction: column;
    gap:20px;
}

.cbar-cotcon{
    display:flex;
    flex-direction: column!important;
    gap:10px;

    .row{
      display: flex;      
      gap:10px;      
      justify-content: flex-start!important;
      align-items: center;
    }
}