.main {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 15px;
  height: 350px;
  border: 2px solid transparent;
  transition: border-color 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1/1;
  }

  .btncon {
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 10px;
      transform: translateY(100%);
      transition: transform 0.2s ease-in-out;
  }

  &:hover .btncon {
      transform: translateY(0);
  }

  .btn {
      width: 200px !important;
      background-color: #fff;
      border: 1px solid #000;
      padding: 10px;
      color: #000;
      font-weight: 600;
      cursor: pointer;
      font-size: medium;

      &:hover{
        background-color:#000 ;
        color: #fff;
      }
      
  }

  .wishlishtcon {
      position: absolute;
      top: 20px;
      right: 35px;
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: center;
      padding: 5px;
      opacity: 0;
      transform: translateX(100%);
      transition: transform 0.2s ease-in-out;
      cursor: pointer;

      .wishicon {
          font-size: 25px !important;
      }
  }

  &:hover .wishlishtcon {
      opacity: 1;
      transform: translateX(0);
  }

  &:hover {
      border-color: #000; /* Simple border color change */
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); /* Add a subtle shadow */
  }

  @media (max-width: 768px) {
      .main {
          padding: 10px;
      }
      .img {
          aspect-ratio: 2/2;
          height: auto;
      }
      .btncon {
          transform: translateY(0);
          transition: none;
      }
      .wishlishtcon {
          right: 20px;
          opacity: 1;
          transform: translateX(0);
          transition: none;
      }
  }
}
