.icon-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 5px !important;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.icon {
  transition: transform 0.3s ease-in-out;
}

/* Trigger the bounce animation on hover */
.icon-container:hover .icon {
  animation: bounce 1s infinite;
}
.hm-icon {
  font-size: 38px !important;
  font-style: normal;
  font-weight: lighter !important;
}

/* Keyframes for bounce animation */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.b2bheading {
  width: 100%;
  text-align: center;
  margin-top: 25px !important;
  margin-bottom: 25px !important;

  h1 {
    font-size: 60px;
    font-weight: bold;
    color: #136e95;
    position: relative;
    display: inline-block;
    animation: fadeInUp 1s ease-in-out;
    &:after {
      content: "";
      display: block;
      width: 50%;
      margin: 0 auto;
      height: 5px;
      background-color: #d24838;
      margin-top: 10px;
      animation: slideIn 0.6s ease-in-out 0.5s;
    }
  }

  h5 {
    font-size: 20px;
    color: #173b45 !important;
    margin-top: 15px;
    animation: fadeInUp 1.2s ease-in-out;
  }
}

// Keyframes for animations
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* HomePage.scss */

.subheading {
  text-align: center;
  opacity: 0;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;

  &.in-view {
    opacity: 1;
    transform: translateY(0);
  }

  h5 {
    font-size: 1.2rem;
    font-weight: 400;
    color: #ff007c;
    margin-bottom: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.animated-grid-item {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  opacity: 1;
  transform: translateY(0);
  // &.in-view {
  //   opacity: 1;
  //   transform: translateY(0);
  // }
}

.animated-grid-item-rotate {
    opacity: 0;
    transform: rotate(-10deg);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    
    &.in-view {
      opacity: 1;
      transform: rotate(0);
    }
  }
  

.animated-grid-item-zoom {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;

  &.in-view {
    opacity: 1;
    transform: scale(1);
  }
}
