.carousel {
    width: 100%;
    overflow: hidden;
    position: relative;

    .slider {
        display: flex;
        transition: transform 1s ease-in-out;
        width: 100%;
        height: 100%;
    }

    img {
        width: 100%;
        height: 100%;
        flex-shrink: 0; /* Prevent images from shrinking */
        object-fit: contain;
    }

    .dots {
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        gap: 10px;
    }

    .dot {
        width: 10px;
        height: 10px;
        background-color: #bbb;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .dot.active {
        background-color: #fff;
    }

    .prev, .next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        padding: 10px;
        cursor: pointer;
        font-size: 18px;
        z-index: 1;
    }

    .prev {
        left: 10px;
    }

    .next {
        right: 10px;
    }

    .prev:hover, .next:hover {
        background-color: rgba(0, 0, 0, 0.7);
    }
}

@media (max-width: 768px) {
    .carousel {
        height: auto;

        img {
            object-fit: contain;
            // aspect-ratio: 3/2;
            width: 100%;
            height: auto;
        }
    }
}
