
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
  --White: ##fff;
  --red: #F5004F;
  --black: #222;
  --ft-bg: #004658;
}
*{
  
  font-family: "Lato", sans-serif;
}

body {
  margin: 0;
  background: var(--White);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



::-webkit-scrollbar {
  width: 2px;
  height: 4px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  height: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

* {
  margin: 0;
  padding: 0;
}

html {
  height: -webkit-fill-available;
}

body {
   height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
   position: relative;
}