@import url('https://fonts.googleapis.com/css2?family=Risque&display=swap');
 

 
 

.avatar{
  display: flex;
  justify-content: center;
}
.welcome{
  width:100%;
  text-align: center;
  font-family: 'Risque';
  font-style: normal;
  font-weight: 400;
  font-size: 62px;
  line-height: 60px;
  color: #FFFFFF;
}

.login{
  background-color: #FFC42A!important;
  color: black!important;
  font-weight: 600;
}



.background-image {  
  position: -webkit-sticky;
  right: 0; /* Align to the right edge */
  bottom: 0; /* Align to the bottom edge */
  width: auto; /* Allow width to scale based on height */
  height: 100%; /* Set height to 100% of the container */
  object-fit: scale-down; /* Ensures the image is fully visible without distortion */
}
.rect{
  position: absolute;
  height: 200px;
  width: auto;
  object-fit: contain;
  z-index: 1;
  bottom: 100px;
  right: auto;
}

.lamp{
  position: absolute;
  height:100px;
  width: auto;
  object-fit: contain;
  z-index: 1;
  top: 0px;
  left: 50px;
}
.userlog-main{  
  display: flex;
  flex-direction: column;
  position: relative;
 }

.userlog-wave {
  position: relative;
  top: 0;
  height: 129px;
  object-fit: scale-down; /* This ensures the entire image is visible but may leave space */
  width: 300px;
}


.mob-welcome{
  width:100%;
  text-align: center;
  font-family: 'Risque';
  font-style: normal;
  font-weight: 400;
  font-size: 62px;
  line-height: 60px;
  color: #000;
}
  
.mob-signin{
  width:100%;
  text-align: center;  
  font-style: normal;
  font-weight: 400;
  font-size: 18px;  
  color: #000;
}

.mob-signbtncon{
  display: flex;
  justify-content: flex-end;
  align-items: center;  
  gap:10px;
}
.mob-button{
  width: 56px;
  height: 34px;
  background: linear-gradient(135deg, #F97794 0%, #623AA2 100%);
  border-radius: 17px;
  border:1px solid #fff;
  margin-bottom: 100px;
  color:"#fff"
}