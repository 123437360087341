.image-list {
    width: 100%;
    margin: 0 auto;
    padding: 10px!important;
    display:flex;
    justify-content: center;
    position: relative;
  }
  
  .pc-card {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
    background: white;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
     
    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
      transition: transform 0.3s ease;
    }
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      border-left: 3px solid rgba(255, 0, 132, 0);
      border-radius: 10px 0 0 10px;
      transition: width 0.3s ease, border-color 0.3s ease;
      z-index: 1;
    }
  
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 100%;
      border-right: 3px solid rgba(255, 0, 132, 0);
      border-radius: 0 10px 10px 0;
      transition: width 0.3s ease, border-color 0.3s ease;
      z-index: 1;
    }
  
    &:hover {
      transform: translateY(0.8);
    //   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
  
      img {
        transform: scale(0.90);
      }
  
      &::before {
        width: 100%;
        border-color: #136E95;
      }
  
      &::after {
        width: 100%;
        border-color: #136E95;
      }
    }
  }
  
  .pc-card:hover .MuiImageListItemBar-root {
    opacity: 1;
    transform: translateY(0);
  }
  
  .MuiImageListItemBar-root {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  