.banrleft {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.imageListItem {
  position: relative !important;
  border: 1px solid silver;
  padding: 5px;
  
  .deleteIcon {
    position: absolute!important;
    top: 2px;
    right: 2px;
    color: white!important;
    background-color: rgba(0, 0, 0, 0.7)!important;
    border-radius: 50%;
    padding: 2px!important;
    z-index: 999;
  }
}

.banrright {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
